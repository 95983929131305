import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class WaitHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        const { soonInfo } = this.props;
        const anounce = soonInfo?.components?.anounce;

        return (
            <>
                <div ref={this.parent} className="waitHeader">
                    <div className="waitHeader__inner">
                        <div className="waitHeader__content">
                            <div className="waitHeader__title">
                                До старта акции:
                                <span className="_counter">{anounce?.counter?.days}</span>
                                <span>{anounce?.counter?.title}</span>
                            </div>
                            <div className="waitHeader__description">
                                дата старта - <span>1 апреля</span>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
    };
}

export default connect(mapStateToProps)(WaitHeader);

WaitHeader.propTypes = {
    device: PropTypes.string,
    soonInfo:PropTypes.object
};

const pages = [
    {
        links: ['404'],
        name: '404',
        content: '404',
    },
    {
        links: ['', undefined],
        name: 'index',
        content: 'Главная',
    },
    {
        links: ['about'],
        name: 'about',
        content: 'О продукте',
    },
    {
        links: ['faq'],
        name: 'faq',
        content: 'Вопрос/Ответ',
    },
    {
        links: ['anket'],
        name: 'anket',
    },
    {
        links: ['prize-anket'],
        name: 'prizeAnket',
    },
    {
        links: ['act'],
        name: 'act',
    },
    {
        links: ['profile'],
        name: 'profile',
    },
    {
        links: ['login'],
        name: 'login',
        mainPage: 'index',
        isPopup: true,
    },
    {
        links: ['registration'],
        name: 'registration',
        mainPage: 'index',
        isPopup: true,
    },
    {
        links: ['cheques'],
        name: 'cheques',
        mainPage: (reduxStore) => (reduxStore?.user ? 'profile' : 'index'),
        isPopup: true,
    },
];

export default pages;

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from './Button.jsx';
import { dispatcher } from '../redux/redux';
import Animate from './Animate.jsx';

class Cookies extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        const { cookiesShow, mainContent } = this.props;
        const policy = mainContent?.footer?.menu?.find((item) =>
            item.title.includes('Политика конфиденциальности'),
        );

        return (
            <Animate className="body__cookies" isShow={!cookiesShow}>
                <div className="cookies">
                    <p className="cookies__content">
                        Сайт использует cookie, что позволяет получать информацию о вас. Это нужно,
                        чтобы улучшать сайт. Продолжая пользоваться сайтом, вы соглашаетесь с
                        использованием cookie и предоставления их сторонним партнерам. Вам нужно
                        принять это либо покинуть сайт
                    </p>
                    <div className="cookies__buttons">
                        <div className="cookies__button">
                            <Button
                                className="_minSize _green"
                                onClick={() => {
                                    dispatcher({
                                        type: 'cookiesShow',
                                        data: 'accept',
                                        storage: { isSet: true },
                                    });
                                }}
                            >
                                ПРИНЯТЬ
                            </Button>
                        </div>
                        <a
                            className="cookies__button"
                            href="https://moya-semia.ru/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Button className="_minSize _darkEmpty">ПОКИНУТЬ САЙТ</Button>
                        </a>
                    </div>
                    <a
                        href={policy?.url}
                        className="cookies__link"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Пользовательское соглашение
                    </a>
                </div>
            </Animate>
        );
    }
}

function mapStateToProps(state) {
    return {
        cookiesShow: state.cookiesShow,
        mainContent: state.mainContent,
    };
}

export default connect(mapStateToProps)(Cookies);

Cookies.propTypes = {
    cookiesShow: PropTypes.bool,
    mainContent: PropTypes.object,
};

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import getPage from '../functions/getPage';

import ListAbsoluteMain from './ListAbsoluteMain.jsx';
import removeTransition from '../functions/removeTransition';

class Pages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.getPagesOrder = this.getPagesOrder.bind(this);
        this.renderPage = this.renderPage.bind(this);
        this.changePage = this.changePage.bind(this);
    }

    getPagesOrder(isAll = false) {
        const { storePages, filter, pages } = this.props;
        const currentPagesKeys = Object.keys(storePages).filter(
            (pageKey) =>
                pages[pageKey] &&
                (isAll || storePages[pageKey].isShow) &&
                filter(getPage({ name: pageKey })),
        );

        return currentPagesKeys.map((pageKey) => getPage({ name: pageKey }));
    }

    renderPage({ prop: name }) {
        const { currentPageName } = this.state;
        const { classNamePage = '', pages, context, windowHeight, isFull = true } = this.props;
        const allPages = this.getPagesOrder(true);
        const contextPage = pages[name];
        const indexCurrentPage = allPages.findIndex((page) => page.name === currentPageName);
        const indexPage = allPages.findIndex((page) => page.name === name);
        const page = getPage({ name });

        let state = '';

        if (indexPage > indexCurrentPage) {
            state = '_next';
        }

        if (indexPage < indexCurrentPage) {
            state = '_prev';
        }

        return (
            <div
                className={`${classNamePage} _${name} ${page?.className || ''} ${page?.isPopup ? '_popup' : ''} ${state}`}
                style={isFull ? { height: `${windowHeight}px` } : {}}
            >
                {contextPage?.render.call(context)}
            </div>
        );
    }

    checkChangePage() {
        const { storePages } = this.props;
        const currentPage = this.getPagesOrder(true).find((page) => storePages[page.name].isShow);

        if (currentPage && currentPage.name !== this.state.currentPageName) {
            setTimeout(() => {
                this.setState({ currentPageName: currentPage.name });
            }, 10);
        }
    }

    changePage({ detail: { changeIsHard } }) {
        const { classNamePage } = this.props;

        if (changeIsHard && classNamePage) {
            removeTransition({ item: `.${classNamePage}`, isCurrent: true });
        }
    }

    componentDidMount() {
        this.checkChangePage();

        document.addEventListener('changePage', this.changePage);
    }

    componentDidUpdate() {
        this.checkChangePage();
    }

    componentWillUnmount() {
        document.removeEventListener('changePage', this.changePage);
    }

    render() {
        const { isInit } = this.state;
        const { className = '', classNamePage = '', callback } = this.props;

        return (
            <>
                <ListAbsoluteMain
                    className={className}
                    items={this.getPagesOrder()}
                    renderItem={this.renderPage}
                    classNameItem={classNamePage}
                    prop="name"
                    isNotParams={true}
                    isNotParamsItem={true}
                    isShowForce={!isInit}
                    callback={() => {
                        if (!this.state.isInit) {
                            this.setState({ isInit: true });
                        }

                        if (callback) {
                            callback();
                        }
                    }}
                />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        storePages: state.pages,
        windowHeight: state.windowHeight,
    };
}

export default connect(mapStateToProps)(Pages);

Pages.propTypes = {
    storePages: PropTypes.object,
    pages: PropTypes.object,
    filter: PropTypes.func,
    className: PropTypes.string,
    classNamePage: PropTypes.string,
    context: PropTypes.object,
    windowHeight: PropTypes.number,
    isFull: PropTypes.bool,
    callback: PropTypes.func,
};

import React from 'react';

class Game extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        document.dispatchEvent(new CustomEvent('readyGame'));

        const script = document.createElement('script');

        script.src = '/game/static/js/main.850a8fa0.js';

        document.querySelector('head').appendChild(script);
    }

    render() {
        return <></>;
    }
}

export default Game;

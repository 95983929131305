import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Table from '../../components/Table.jsx';
import AnimateChange from '../../components/AnimateChange.jsx';
import Animate from '../../components/Animate.jsx';
import Loader from '../../components/Loader.jsx';

class ProfileCheques extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        const { items, raffles } = this.props;

        return (
            <>
                <div ref={this.parent} className="profileCheques">
                    <div className="profileCheques__inner">
                        <div className="profileCheques__head">
                            <div className="innerPageHead">
                                <div className="innerPageHead__description">МОИ ЧЕКИ</div>
                            </div>
                        </div>
                        <div className="profileCheques__content">
                            <div className="profileCheques__table">
                                <Table
                                    name="cheques"
                                    items={items?.map((item, key) => ({ ...item, id: key })) || []}
                                    isReady={!!items}
                                />
                            </div>
                            <div className="profileCheques__info">
                                <Animate className="profileCheques__infoLoader" isShow={!raffles}>
                                    <div className="profileCheques__infoLoaderItem">
                                        <Loader />
                                    </div>
                                </Animate>
                                <AnimateChange
                                    className="profileCheques__infoInner _center"
                                    renderKey={raffles?.nextRaffleTitle}
                                    parentStyles={['width']}
                                    minWidth={30}
                                >
                                    <>
                                        <span>{raffles?.nextRaffleTitle}</span>&nbsp;{raffles?.nextRaffle}
                                    </>
                                </AnimateChange>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
    };
}

export default connect(mapStateToProps)(ProfileCheques);

ProfileCheques.propTypes = {
    device: PropTypes.string,
    items: PropTypes.array,
    raffles: PropTypes.object,
};

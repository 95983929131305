import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Popup from './Popup.jsx';
import Game from './Game.jsx';
import handlerPopup from '../functions/handlerPopup';
import checkAuth from '../functions/checkAuth';

class GamePopup extends Popup {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    isPrivate = true;

    gameEnd() {
        document.dispatchEvent(new CustomEvent('updateProfile'));

        checkAuth();

        handlerPopup({ name: 'gamePopup', isShow: false });
    }

    componentDidMount() {
        document.addEventListener('gameEnd', this.gameEnd);
    }

    componentWillUnmount() {
        document.removeEventListener('gameEnd', this.gameEnd);
    }

    render() {
        return (
            <>
                <Popup name="gamePopup">
                    <div className="popup__game">
                        <div id="marathon-app-wrapper">
                            <div id="marathon-app-container">
                                <div id="marathon-app-root">
                                    <Game />
                                </div>
                            </div>
                        </div>
                    </div>
                </Popup>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
    };
}

export default connect(mapStateToProps)(GamePopup);

GamePopup.propTypes = {
    device: PropTypes.string,
};

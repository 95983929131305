import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../../components/Button.jsx';
import Icon from '../../components/Icon.jsx';
import DashedBorder from '../../components/DashedBorder.jsx';

class Start extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        const { setBlock, uploadQr, loadingKey } = this.props;

        return (
            <>
                <div className="popup__head">
                    <div className="popup__headTitle">регистрация чека</div>
                </div>
                <div className="popup__content">
                    <div
                        className="popup__scan"
                        onClick={() => {
                            setBlock({ name: 'scan' });
                        }}
                    >
                        <div className="popup__scanBack">
                            <DashedBorder />
                        </div>
                        <i className="popup__scanIcon">
                            <Icon name="scan" />
                        </i>
                        <p className="popup__scanDescription">
                            Нажмите, чтобы
                            <br /> отсканировать QR-код с чека
                        </p>
                    </div>
                </div>
                <div className="popup__foot">
                    <div className="popup__buttons">
                        <div className="popup__button _height">
                            <Button
                                className="_darkOrange _mediumSize"
                                onClick={() => {
                                    setBlock({ name: 'form', qrType: 'typing' });
                                }}
                            >
                                ввести данные
                                <br className="_desktopMedia" /> чека вручную
                            </Button>
                        </div>
                        <label className="popup__button _height">
                            <input
                                type="file"
                                style={{ display: 'none' }}
                                onChange={({ target }) => uploadQr({ target, qrType: 'qr-photo' })}
                                disabled={!!loadingKey}
                            />
                            <Button
                                className="_darkOrange _mediumSize"
                                loader={loadingKey === 'file'}
                            >
                                загрузить фото
                                <br className="_desktopMedia" /> qr-кода с чека
                            </Button>
                        </label>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
    };
}

export default connect(mapStateToProps)(Start);

Start.propTypes = {
    device: PropTypes.string,
    setBlock: PropTypes.func,
    uploadQr: PropTypes.func,
    loadingKey: PropTypes.string,
};

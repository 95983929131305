import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from './Button.jsx';
import Link from './Link.jsx';
import ListAbsoluteMain from './ListAbsoluteMain.jsx';

import getNav from '../functions/getNav';
import { dispatcher } from '../redux/redux';
import changePage from '../functions/changePage';
import getCurrentPage from '../functions/getCurrentPage';
import logout from '../functions/logout';
import handlerLoading from '../functions/handlerLoading';

class TopBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderButton = this.renderButton.bind(this);

        this.parent = React.createRef();
    }

    keys = {
        index: 'main',
        instruction: 'howto',
        about: 'about',
        prizes: 'prizes',
        winners: 'winners',
        faq: 'faq',
    };

    getButtons() {
        const { user, pages } = this.props;
        const currentPage = getCurrentPage({ pages, filter: (page) => !page.isPopup });

        if (currentPage === 'anket' && user?.status === 'ANKET_REQUIRED') {
            return [{ key: 'logout' }];
        }

        if (user?.status === 'EMAIL_CONFIRM_REQUIRED') {
            return [{ key: 'logout' }];
        }

        return [{ key: 'cheques' }, { key: 'profile' }];
    }

    renderButton({ prop: key }) {
        const { loadingKey } = this.state;
        const { user } = this.props;

        if (key === 'logout') {
            return (
                <div className="topBar__button">
                    <Button
                        className="_red"
                        onClick={() => {
                            handlerLoading.call(this, 'logout').then(() => {
                                logout().then(() => {
                                    handlerLoading.call(this, null);
                                });
                            });
                        }}
                        loader={loadingKey === 'logout'}
                    >
                        Выход
                    </Button>
                </div>
            );
        }

        if (key === 'cheques') {
            return (
                <Link href={user ? 'cheques' : 'login'} className="topBar__button">
                    <Button className="_green">ЗАГРУЗИТЬ ЧЕК</Button>
                </Link>
            );
        }

        return (
            <Link href={user ? 'profile' : 'login'} className="topBar__button">
                <Button className="_red">ЛИЧНЫЙ КАБИНЕТ</Button>
            </Link>
        );
    }

    render() {
        const { mobileMenuShow, mainContent, user } = this.props;

        return (
            <div className="body__topBar">
                <div className="topBar">
                    <div className="topBar__inner">
                        <div
                            className={`topBar__menu ${mobileMenuShow ? '_active' : ''}`}
                            onClick={() => {
                                dispatcher({ type: 'mobileMenuShow', data: !mobileMenuShow });
                            }}
                        >
                            <div className="topBar__menuItem"></div>
                            <div className="topBar__menuItem"></div>
                            <div className="topBar__menuItem"></div>
                        </div>
                        <div className="topBar__block _logo">
                            <div
                                className="topBar__logo"
                                onClick={() => {
                                    document.dispatchEvent(
                                        new CustomEvent('indexAncor', {
                                            detail: { name: 'index' },
                                        }),
                                    );

                                    dispatcher({
                                        type: 'mobileMenuShow',
                                        data: false,
                                    });
                                }}
                            >
                                <img
                                    src={require('../media/logo-mf.svg').default}
                                    alt=""
                                    className="topBar__logoIcon"
                                />
                            </div>
                        </div>
                        <div className="topBar__block _nav">
                            <div className="topBar__nav">
                                {getNav().map((item) => {
                                    const { name, isAncor } = item;
                                    const LinkTag = isAncor ? 'div' : Link;
                                    const linkProps = isAncor
                                        ? {
                                              onClick: () => {
                                                  document.dispatchEvent(
                                                      new CustomEvent('indexAncor', {
                                                          detail: { name },
                                                      }),
                                                  );
                                              },
                                          }
                                        : { pageName: name };
                                    const key = this.keys[name];
                                    const content = mainContent.header?.menu?.find(
                                        (menuItem) => menuItem.key === key,
                                    )?.title;

                                    return (
                                        <div
                                            className={`topBar__navItem ${content ? '_ready' : ''}`}
                                            key={name}
                                        >
                                            <LinkTag className="topBar__navLink" {...linkProps}>
                                                {content}
                                            </LinkTag>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="topBar__block _buttons">
                            <ListAbsoluteMain
                                className="topBar__buttons"
                                items={this.getButtons()}
                                renderItem={this.renderButton}
                                classNameItem="topBar__button"
                                prop="key"
                                // paramsParent={{ width: true }}
                                styles={['width', 'height']}
                                itemParams={['offsetRight']}
                                resizeParent={document.querySelector('.body')}
                            />
                        </div>
                        <img
                            src={require('../media/icon-user.svg').default}
                            alt=""
                            className="topBar__icon"
                            onClick={() => {
                                changePage({ href: user ? 'profile' : 'login' });
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        mobileMenuShow: state.mobileMenuShow,
        mainContent: state.mainContent,
        user: state.user,
        pages: state.pages,
    };
}

export default connect(mapStateToProps)(TopBar);

TopBar.propTypes = {
    mobileMenuShow: PropTypes.bool,
    mainContent: PropTypes.object,
    user: PropTypes.object,
    pages: PropTypes.object,
};

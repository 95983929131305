import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class IndexPrizes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        const { prizes, nextRaffle } = this.props;

        return (
            <>
                <div ref={this.parent} className="indexPrizes" id="prizes">
                    <div className="indexPrizes__inner">
                        <div className="indexPrizes__block _start">
                            <div className="indexPrizes__blockHead">
                                <img
                                    src={require('../../media/flag-1.png')}
                                    alt=""
                                    className="indexPrizes__blockTitle"
                                />
                                <div className="indexPrizes__blockHeadPrize">
                                    <img
                                        src={require('../../media/prize 8 0.png')}
                                        alt=""
                                        className="indexPrizes__blockHeadPrizeImage"
                                    />
                                    <p className="indexPrizes__blockHeadPrizeDescription">
                                        Стикерпак
                                        <br />
                                        за первый чек
                                    </p>
                                </div>
                                <p
                                    className="indexPrizes__blockDescription"
                                    dangerouslySetInnerHTML={{
                                        __html: prizes?.guarantied.title,
                                    }}
                                ></p>
                            </div>
                            <div className="indexPrizes__blockContent">
                                {[0, 1, 2].map((key) => {
                                    const prize = prizes?.guarantied.items[key];

                                    return (
                                        <div
                                            className={`indexPrizes__blockCard ${
                                                prize ? '_show' : ''
                                            }`}
                                            key={key}
                                        >
                                            <div className="indexPrizes__blockCardHead">
                                                <img
                                                    src={prize?.thumb}
                                                    alt=""
                                                    className="indexPrizes__blockCardImage"
                                                />
                                            </div>
                                            <div className="indexPrizes__blockCardContent">
                                                <div className="indexPrizes__blockCardDescription">
                                                    {prize?.title ? (
                                                        <>
                                                            {prize?.title}
                                                            {key === 0 ? (
                                                                <span className="_min">1</span>
                                                            ) : null}
                                                        </>
                                                    ) : (
                                                        <>
                                                            —<br />—
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="indexPrizes__block _end">
                            <div className="indexPrizes__blockHead">
                                <img
                                    src={require('../../media/flag-2.png')}
                                    alt=""
                                    className="indexPrizes__blockTitle"
                                />
                                <div className="indexPrizes__blockHeadOffer">
                                    <div className="indexPrizes__blockHeadOfferTitle">
                                        {nextRaffle?.title}
                                    </div>
                                    <div className="indexPrizes__blockHeadOfferDescription">
                                        {nextRaffle?.description}
                                    </div>
                                </div>
                                <p
                                    className="indexPrizes__blockDescription"
                                    dangerouslySetInnerHTML={{
                                        __html: prizes?.weekly.title,
                                    }}
                                ></p>
                            </div>
                            <div className="indexPrizes__blockContent">
                                {[0, 1, 2, 3].map((key) => {
                                    const prize = prizes?.weekly.items[key];

                                    return (
                                        <div
                                            className={`indexPrizes__blockCard ${
                                                prize ? '_show' : ''
                                            }`}
                                            key={key}
                                        >
                                            <div className="indexPrizes__blockCardHead">
                                                <img
                                                    src={prize?.thumb}
                                                    alt=""
                                                    className="indexPrizes__blockCardImage"
                                                />
                                            </div>
                                            <div className="indexPrizes__blockCardContent">
                                                <div className="indexPrizes__blockCardDescription">
                                                    {prize?.title || <>—</>}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
    };
}

export default connect(mapStateToProps)(IndexPrizes);

IndexPrizes.propTypes = {
    device: PropTypes.string,
    nextRaffle: PropTypes.object,
    prizes: PropTypes.object,
};

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import setSpacesInText from '../functions/setSpacesInText';
import Button from './Button.jsx';
import removeTransition from '../functions/removeTransition';

class ProductCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    handlerCard(isShow) {
        const { id } = this.props;
        clearTimeout(this.timerId);

        this.setState({ isShow });

        this.timerId = setTimeout(() => {
            removeTransition({
                item: `.productCard[data-id="${id}"] .productCard__content`,
                onlyChild: true,
            });
            this.setState({ isShowInfo: isShow });
        }, 150);
    }

    render() {
        const { isShow, isShowInfo } = this.state;
        const {
            frontThumb,
            volume = [],
            backThumb,
            title,
            description,
            id,
            scrollToShops,
            wheretobuy = [],
        } = this.props;

        return (
            <>
                <div
                    ref={this.parent}
                    className={`productCard ${isShow ? '_active' : ''} ${
                        isShowInfo ? '_showInfo' : ''
                    }`}
                    data-id={id}
                >
                    <div className="productCard__inner">
                        <div className="productCard__content">
                            <div className="productCard__images">
                                <img src={frontThumb} alt="" className="productCard__image" />
                            </div>

                            <div className="productCard__types">
                                {volume.map((volumeItem) => (
                                    <div className="productCard__type" key={volumeItem}>
                                        {volumeItem}
                                    </div>
                                ))}
                            </div>
                            <div
                                className="productCard__title"
                                dangerouslySetInnerHTML={{ __html: setSpacesInText(title) }}
                            ></div>
                            <div
                                className="productCard__btn"
                                onClick={() => this.handlerCard(true)}
                            >
                                О продукте
                            </div>
                            {wheretobuy?.length > 0 && (
                                <div className="productCard__buy">
                                    <Button className="_green" onClick={scrollToShops}>
                                        Купить
                                    </Button>
                                </div>
                            )}
                        </div>
                        <div className="productCard__content _back">
                            <img src={backThumb} alt="" className="productCard__image _min" />
                            <div
                                className="productCard__title"
                                dangerouslySetInnerHTML={{ __html: setSpacesInText(title) }}
                            ></div>
                            <p
                                className="productCard__description"
                                dangerouslySetInnerHTML={{ __html: setSpacesInText(description) }}
                            ></p>
                            <div className="productCard__types">
                                {volume.map((volumeItem) => (
                                    <div className="productCard__type" key={volumeItem}>
                                        {volumeItem}
                                    </div>
                                ))}
                            </div>
                            <div
                                className="productCard__btn"
                                onClick={() => this.handlerCard(false)}
                            >
                                ПЕРЕВЕРНУТЬ
                            </div>
                            {wheretobuy.length > 0 && (
                                <div className="productCard__buy">
                                    <Button className="_green" onClick={scrollToShops}>
                                        Купить
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
    };
}

export default connect(mapStateToProps)(ProductCard);

ProductCard.propTypes = {
    volume: PropTypes.array,
    frontThumb: PropTypes.string,
    backThumb: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.string,
    group: PropTypes.string,
    scrollToShops: PropTypes.func,
    wheretobuy: PropTypes.array,
};

import React from 'react';
import PropTypes from 'prop-types';

import File from '../classes/File';

import setSpacesInText from '../functions/setSpacesInText';

import Checkbox from './Checkbox.jsx';
import Field from './Field.jsx';
import Upload from './Upload.jsx';

class FormPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            errors: [],
        };

        this.handlerField = this.handlerField.bind(this);
        this.renderField = this.renderField.bind(this);
        this.handlerUpload = this.handlerUpload.bind(this);
        this.setSize = this.setSize.bind(this);

        this.parent = React.createRef();
    }

    handlerField({ action, name, value }) {
        return new Promise((resolve) => {
            if (action !== 'change') {
                resolve();
            } else {
                this.setState((state) => {
                    const newState = { ...state };
                    const fields = { ...newState.fields };

                    fields[name] = value;

                    newState.fields = fields;

                    return newState;
                }, resolve);
            }
        });
    }

    formData = new FormData();

    handlerUpload(name, { target }) {
        this.handlerFile
            .uploadFiles({
                target,
                getName: () => name,
                formData: this.formData,
            })
            .then(({ resultFiles }) => {
                const [file] = resultFiles;

                this.setState((state) => {
                    const newState = { ...state };
                    const fields = { ...newState.fields };

                    fields[name] = file;

                    newState.fields = fields;

                    return newState;
                });
            });
    }

    renderField({ name }) {
        const { fields } = this.state;
        const fieldInfo = this.fields[name];
        const value = fields[name];
        const support =
            typeof fieldInfo.support === 'function' ? fieldInfo.support() : fieldInfo.support;

        return (
            <div className={`form__field _${fieldInfo.type}`} key={name}>
                {fieldInfo.type === 'checkbox' ? (
                    <>
                        <Checkbox name={name} checked={value} onChange={this.handlerField}>
                            {support}
                        </Checkbox>
                    </>
                ) : fieldInfo.type === 'upload' ? (
                    <>
                        <div className="field _upload">
                            <p
                                className="field__support"
                                dangerouslySetInnerHTML={{
                                    __html: setSpacesInText(support),
                                }}
                            ></p>
                            <div className="field__box">
                                <Upload
                                    name={name}
                                    file={value}
                                    onChange={this.handlerUpload.bind(this, name)}
                                />
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <Field
                            {...fieldInfo}
                            support={support}
                            name={name}
                            value={value}
                            onChange={this.handlerField}
                        />
                    </>
                )}
            </div>
        );
    }

    setSuccess() {
        this.setState({ isSuccess: true }, () => {
            setTimeout(() => {
                this.setState({ isCompleteSuccess: true });
            }, 10);
        });
    }

    setSize() {
        const form = this.parent.current.querySelector('.form');

        if (form) {
            form.style.height = null;

            this.setState({ formHeight: form.offsetHeight });
        }
    }

    componentDidMount() {
        this.setSize();

        this.handlerFile = new File({});

        document.addEventListener('changeWidthWindow', this.setSize);
    }

    componentWillUnmount() {
        document.removeEventListener('changeWidthWindow', this.setSize);
    }
}

export default FormPage;

FormPage.propTypes = {
    device: PropTypes.string,
};

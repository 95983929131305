import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AсtForm from './act/Form.jsx';

import Footer from '../components/Footer.jsx';
import Page from '../components/Page.jsx';

class Act extends Page {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    pageName = 'aсt';

    render() {
        return (
            <>
                <div ref={this.parent} className="page _act _inner">
                    <div className="page__section">
                        <AсtForm />
                    </div>
                    <div className="page__section _withCat">
                        <Footer />
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
    };
}

export default connect(mapStateToProps)(Act);

Act.propTypes = {
    device: PropTypes.string,
};

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import PrizeCard from '../../components/PrizeCard.jsx';
import Loader from '../../components/Loader.jsx';
import Animate from '../../components/Animate.jsx';

class ProfilePrizes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    checkReady() {
        const { prizes } = this.props;

        if (prizes && !this.isReady) {
            this.isReady = true;

            const inner = this.parent.current.querySelector('.profilePrizes__contentInner');

            this.setState({ contentHeight: inner.offsetHeight, isReady: true });

            setTimeout(() => {
                this.setState({ contentHeight: null });
            }, 500);
        }
    }

    componentDidMount() {
        this.checkReady();
    }

    componentDidUpdate() {
        this.checkReady();
    }

    render() {
        const { contentHeight, isReady } = this.state;
        const { prizes } = this.props;

        return (
            <>
                <div ref={this.parent} className={`profilePrizes ${isReady ? '_ready' : ''}`}>
                    <div className="profilePrizes__inner">
                        <div className="profilePrizes__head">
                            <div className="innerPageHead">
                                <div className="innerPageHead__description">МОИ ПРИЗЫ</div>
                            </div>
                        </div>
                        <div
                            className="profilePrizes__content"
                            style={
                                !isReady
                                    ? { height: `20rem` }
                                    : contentHeight
                                    ? { height: `${contentHeight}px` }
                                    : {}
                            }
                        >
                            <Animate className="profilePrizes__loader" isShow={!isReady}>
                                <div className="profilePrizes__loaderItem">
                                    <Loader />
                                </div>
                            </Animate>
                            <div className="profilePrizes__contentInner">
                                <div className="profilePrizes__cards">
                                    {(prizes || []).map((card, key) => (
                                        <div className="profilePrizes__card" key={key}>
                                            <PrizeCard {...card} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
    };
}

export default connect(mapStateToProps)(ProfilePrizes);

ProfilePrizes.propTypes = {
    device: PropTypes.string,
    prizes: PropTypes.array,
};

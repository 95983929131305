import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import setSpacesInText from '../../functions/setSpacesInText';
import changePage from '../../functions/changePage';
import handlerPopup from '../../functions/handlerPopup';

class IndexGame extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        const { game, user } = this.props;
        const gameCounter = user?.gameCounter;

        return (
            <>
                <div
                    ref={this.parent}
                    className={`indexGame ${game ? '_ready' : ''}`}
                    id="instruction"
                >
                    <div className="indexGame__inner">
                        <div
                            className="indexGame__title"
                            dangerouslySetInnerHTML={{
                                __html: game ? setSpacesInText(game?.title) : '—<br/>–',
                            }}
                        ></div>
                        <div className="indexGame__content"></div>
                        <div className="indexGame__button">
                            <div
                                className="gameButton"
                                onClick={() => {
                                    if (!user) {
                                        changePage({ href: 'login' });
                                    } else if (gameCounter === 0) {
                                        changePage({ href: 'profile' });
                                    } else {
                                        handlerPopup({ name: 'gamePopup', isShow: true });
                                    }
                                }}
                            >
                                <div className="gameButton__inner">ИГРАТЬ</div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
    };
}

export default connect(mapStateToProps)(IndexGame);

IndexGame.propTypes = {
    device: PropTypes.string,
    game: PropTypes.object,
    user: PropTypes.object,
};

const pages = require('../redux/pages').default;

export default function getNav() {
    const navOrder = ['index', 'instruction', 'about', 'prizes', 'winners', 'faq'];
    const ancors = {
        instruction: {
            content: 'Как участвовать',
        },
        prizes: {
            content: 'Призы',
        },
        winners: {
            content: 'Победители',
        },
    };

    return navOrder.map((name) => {
        const info = pages.find((page) => page.name === name) || ancors[name];
        const isAncor = !!ancors[name] || name === 'index';

        return { name, content: info.content, isAncor };
    });
}

import axios from 'axios';

import { getCookie } from './handlerCookies';
import { dispatcher } from '../redux/redux';
import logoutActions from './logoutActions';
import getHeaders from './getHeaders';
import requestSuccess from './requestSuccess';
import handlerPopup from './handlerPopup';

const getGameInfo = () =>
    new Promise((resolve) => {
        axios
            .get(`${process.env.REACT_APP_API}/api/Game`, {
                headers: getHeaders(),
            })
            .then(
                (res) => {
                    requestSuccess(res);

                    resolve({ counter: res.data.data?.attemtpsLeft || 0 });
                },
                () => {
                    resolve({ counter: 0 });
                },
            );
    });

export default function checkAuth() {
    const hash = getCookie(process.env.REACT_APP_HASH);

    return new Promise((resolve) => {
        if (!hash) {
            localStorage.removeItem(process.env.REACT_APP_USER);

            dispatcher({ type: 'user', data: null });

            resolve();
        } else {
            axios
                .get(`${process.env.REACT_APP_API}/api/GetParticipantInfo`, {
                    headers: getHeaders(),
                })
                .then(
                    (res) => {
                        requestSuccess(res);

                        const { data: user } = res.data;

                        if (user) {
                            getGameInfo().then(({ counter }) => {
                                user.gameCounter = counter;

                                localStorage.setItem(
                                    process.env.REACT_APP_USER,
                                    JSON.stringify(user),
                                );

                                dispatcher({ type: 'user', data: user });

                                if (user.status === 'EMAIL_CONFIRM_REQUIRED') {
                                    handlerPopup({ name: 'emailPopup', isShow: true });
                                }

                                resolve(user);
                            });
                        } else {
                            resolve();
                        }
                    },
                    (err) => {
                        if (err.response.data.value === 'NEED_AUTH') {
                            logoutActions();
                        }

                        resolve();
                    },
                );
        }
    });
}

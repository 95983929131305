import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import getEndText from '../../functions/getEndText';

import Loader from '../../components/Loader.jsx';
import Animate from '../../components/Animate.jsx';
import Button from '../../components/Button.jsx';
import Link from '../../components/Link.jsx';
import handlerPopup from '../../functions/handlerPopup';

class ProfileGame extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        const { user } = this.props;
        const gameCounter = user?.gameCounter ?? null;
        const isReady = typeof gameCounter === 'number';

        return (
            <>
                <div ref={this.parent} className={`profileGame ${isReady ? '_ready' : ''}`}>
                    <div className="profileGame__inner">
                        <div className="profileGame__content">
                            <div className="profileGame__title">
                                игра с&nbsp;гарантированными призами
                            </div>
                            <Animate className="profileGame__loader" isShow={!isReady}>
                                <div className="profileGame__loaderItem">
                                    <Loader />
                                </div>
                            </Animate>
                            <div className="profileGame__contentInner">
                                {gameCounter > 0 ? (
                                    <>
                                        <div className="profileGame__button">
                                            <div
                                                className="gameButton"
                                                onClick={() => {
                                                    handlerPopup({
                                                        name: 'gamePopup',
                                                        isShow: true,
                                                    });
                                                }}
                                            >
                                                <div className="gameButton__inner">ИГРАТЬ</div>
                                            </div>
                                        </div>
                                        <div className="profileGame__infoWrapper">
                                            <div className={`profileGame__info _center`}>
                                                у тебя {gameCounter}{' '}
                                                {getEndText(gameCounter, [
                                                    'попытка',
                                                    'попытки',
                                                    'попыток',
                                                ])}
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="profileGame__description">
                                            Чтобы играть в игру
                                        </div>
                                        <Link href="cheques" className="profileGame__button _reg">
                                            <Button className="_green _bigSize">
                                                зарегистрируйТЕ чек
                                            </Button>
                                        </Link>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
    };
}

export default connect(mapStateToProps)(ProfileGame);

ProfileGame.propTypes = {
    device: PropTypes.string,
    user: PropTypes.object,
};

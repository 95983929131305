import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import IndexHeader from './index/Header.jsx';
import IndexGame from './index/Game.jsx';
import IndexPrizes from './index/Prizes.jsx';
import IndexMainPrize from './index/MainPrize.jsx';
import IndexWinners from './index/Winners.jsx';
import Footer from '../components/Footer.jsx';
import { dispatcher } from '../redux/redux';
import scrollToPosition from '../functions/scrollToPosition';
import Page from '../components/Page.jsx';

class Index extends Page {
    constructor(props) {
        super(props);
        this.state = {};

        this.handlerScroll = this.handlerScroll.bind(this);

        this.parent = React.createRef();
    }

    pageName = 'index';

    contentName = 'main';

    getScrollPage() {
        return this.parent.current.closest('.body__page');
    }

    scrollToSection({ name, duration = 300 }) {
        const page = this.parent.current.querySelector(`#${name}`);

        if (page) {
            const topBar = document.querySelector('.topBar');
            let offset = 0;

            if (topBar) {
                offset = topBar.offsetHeight;
            }

            scrollToPosition({
                position: 'top',
                parent: this.parent.current.closest('.body__page'),
                classNameElem: `#${name}`,
                offset,
                duration,
            });
        }
    }

    handlerScroll({ detail: { name } }) {
        this.scrollToSection({ name });
    }

    componentDidMount() {
        super.componentDidMount();

        const { indexAncor } = this.props;

        if (indexAncor) {
            this.scrollToSection({ name: indexAncor, duration: 0 });

            dispatcher({ type: 'indexAncor', data: null });
        }

        document.addEventListener('indexScroll', this.handlerScroll);
    }

    componentWillUnmount() {
        super.componentWillUnmount();

        document.removeEventListener('indexScroll', this.handlerScroll);
    }

    render() {
        const { components } = this.state;

        return (
            <>
                <div ref={this.parent} className="page _index">
                    <div className="page__section">
                        <IndexHeader />
                    </div>
                    <div className="page__section _withCat">
                        <IndexGame game={components?.game} />
                    </div>
                    <div className="page__section">
                        <IndexPrizes nextRaffle={components?.nextRaffle} prizes={components?.prizes} />
                    </div>
                    <div className="page__section _prizeAndWinners">
                        <IndexMainPrize prizes={components?.prizes} />
                        <IndexWinners winners={components?.winners} />
                    </div>
                    <div className="page__section _withCat">
                        <Footer footer={components?.footer} />
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        indexAncor: state.indexAncor,
    };
}

export default connect(mapStateToProps)(Index);

Index.propTypes = {
    device: PropTypes.string,
    indexAncor: PropTypes.string,
};

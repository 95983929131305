import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

class CustomHead extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { title, description, link } = this.props;
        let { image } = this.props;

        if (!image) {
            image = `${process.env.REACT_APP_STATIC}/seo/main.jpg`;
        }

        const propsHead = {
            meta: [
                { property: 'og:site_name', content: 'Моя семья' },
                { property: 'og:type', content: 'website' },
            ],
        };

        if (title) {
            propsHead.title = title;
            propsHead.meta.push({
                name: 'twitter:title',
                content: title,
            });
            propsHead.meta.push({
                property: 'og:title',
                content: title,
            });
        }

        if (description) {
            propsHead.meta.push({
                name: 'description',
                content: description,
            });
            propsHead.meta.push({
                name: 'twitter:description',
                content: description,
            });
            propsHead.meta.push({
                property: 'og:description',
                content: description,
            });
        }

        if (image) {
            propsHead.meta.push({
                property: 'image',
                content: image,
            });
            propsHead.meta.push({
                property: 'twitter:card',
                content: 'summary_large_image',
            });
            propsHead.meta.push({
                property: 'twitter:image',
                content: image,
            });
        }

        if (link) {
            propsHead.meta.push({
                name: 'twitter:domain',
                content: process.env.REACT_APP_DOMEN,
            });
            propsHead.meta.push({
                name: 'twitter:url',
                content: link,
            });
            propsHead.meta.push({
                property: 'og:url',
                content: link,
            });
        }

        return (
            <Helmet {...propsHead}>
                <link rel="canonical" href={link} />
            </Helmet>
        );
    }
}

export default CustomHead;

CustomHead.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    link: PropTypes.string,
    image: PropTypes.string,
};

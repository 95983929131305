import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import Popup from './Popup.jsx';
import Field from './Field.jsx';
import Button from './Button.jsx';
import ListAbsoluteMain from './ListAbsoluteMain.jsx';
import getHeaders from '../functions/getHeaders';
import handlerPopup from '../functions/handlerPopup';
import checkAuth from '../functions/checkAuth';


class PromoPopup extends Popup {
    constructor(props) {
        super(props);
        this.state = {
            currentBlock: 'start',
        };

        this.renderBlock = this.renderBlock.bind(this);

        this.parent = React.createRef();
    }

    isPrivate = true;

    getBlocks() {
        const { currentBlock } = this.state;

        return [{ id: currentBlock }];
    }

    renderBlock({ prop: id }) {
        const { fields, error, loadingKey } = this.state;

        return (
            <div className="popup__block">
                {id === 'start' && (
                    <>
                        <div className="popup__head">
                            <div className="popup__headTitle">Ввод промокода</div>
                        </div>
                        <div className="popup__content _minHeight">
                            <div className="popup__fields">
                                <div className="popup__field">
                                    <Field
                                        support="Введите промокод:"
                                        name="promocode"
                                        value={fields?.promocode}
                                        onChange={this.handlerField}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="popup__foot">
                            {this.renderError({ error })}
                            <div className="popup__buttons">
                                <div className="popup__button">
                                    <Button
                                        className="_green _mediumSize"
                                        onClick={() => {
                                            this.send({});
                                        }}
                                        loader={!!loadingKey}
                                    >
                                        Отправить
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {id === 'success' && (
                    <>
                        <div className="popup__head">
                            <div className="popup__headTitle">Ввод промокода</div>
                        </div>
                        <div className="popup__content _minHeight">
                            <div className="popup__success">
                                <div className="popup__successTitle">Поздравляем!</div>
                                <p className="popup__successDescription">
                                    Вам начислен ещё один шанс!
                                </p>
                            </div>
                        </div>
                        <div className="popup__foot">
                            <div className="popup__buttons">
                                <div className="popup__button">
                                    <Button
                                        className="_green _mediumSize"
                                        onClick={() => {
                                            handlerPopup({ name: 'promoPopup', isShow: false });
                                        }}
                                    >
                                        Закрыть
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    }

    send() {
        const { fields } = this.state;

        this.handlerLoading(true).then(() => {
            axios
                .post(
                    `${process.env.REACT_APP_API}/api/PromoCodeRegistration`,
                    {
                        code: fields?.promocode,
                    },
                    {
                        headers: getHeaders(),
                    },
                )
                .then(
                    (res) => {
                        const { result } = res.data;

                        if (result === 'OK') {
                            this.setState({ currentBlock: 'success' });

                            checkAuth();

                            document.dispatchEvent(new CustomEvent('updateProfile'));
                        }
                    },
                    (err) => {
                        this.handlerLoading(null);

                        try {
                            const { errorText } = err.response.data;

                            this.setState({ error: errorText });
                        } catch (error) {
                            this.setState({ error: 'Ошибка сервера' });
                        }
                    },
                );
        });
    }

    render() {
        const { currentBlock, renderUpdateKey } = this.state;

        return (
            <>
                <Popup name="promoPopup">
                    <ListAbsoluteMain
                        className="popup__blocks"
                        items={this.getBlocks()}
                        renderItem={this.renderBlock}
                        classNameItem="popup__block"
                        prop="id"
                        paramsParent={{ width: true }}
                        styles={['height']}
                        isNotParamsItem={true}
                        currentItemKey={currentBlock}
                        allItems={['start', 'success']}
                        resizeParent={document.querySelector('.body')}
                        keyRender={renderUpdateKey}
                    />
                </Popup>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
    };
}

export default connect(mapStateToProps)(PromoPopup);

PromoPopup.propTypes = {
    device: PropTypes.string,
};

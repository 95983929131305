import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import Footer from '../components/Footer.jsx';
import Page from '../components/Page.jsx';
import Header from './wait/Header.jsx';

class Profile extends Page {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    pageName = 'wait';

    componentDidMount() {
        const url = document.querySelector('body').getAttribute('data-soonurl');

        axios.get(url, {}).then(
            (res) => {
                this.setState({ soonInfo: res.data });
            },
            () => {},
        );
    }

    render() {
        const { soonInfo } = this.state;

        return (
            <>
                <div ref={this.parent} className="page _profile">
                    <div className="page__section">
                        <Header soonInfo={soonInfo} />
                    </div>
                    <div className="page__section _withCat">
                        <Footer soonInfo={soonInfo} waitMode={true} />
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
    };
}

export default connect(mapStateToProps)(Profile);

Profile.propTypes = {
    device: PropTypes.string,
};

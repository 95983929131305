import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import setSpacesInText from '../functions/setSpacesInText';

class PrizeCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    renderStatus() {
        const { status, statusTitle, url } = this.props;
        let className;

        if (status === 'SENDING') {
            className = 'process';
        }

        if (status === 'SENT') {
            className = 'completed';
        }

        if (status === 'RECEIVED' || status === 'DOWNLOAD') {
            className = 'received';
        }

        if (status === 'ERROR') {
            className = 'error';
        }

        const Tag = url ? 'a' : 'div';
        const tagProps = url
            ? {
                  href: url,
                  target: '_blank',
              }
            : {};

        return (
            <Tag {...tagProps} className={`prizeCard__status _${className} ${url ? '_link' : ''}`}>
                {statusTitle}
            </Tag>
        );
    }

    render() {
        const { thumb, title, extraTitle, count } = this.props;

        return (
            <>
                <div ref={this.parent} className="prizeCard">
                    <div className="prizeCard__inner">
                        <div className="prizeCard__head">
                            <div className="prizeCard__headInner">
                                <img src={thumb} alt="" className="prizeCard__image" />
                                {count && (
                                    <div className="prizeCard__counter">
                                        <span>{count}</span> шт
                                    </div>
                                )}
                            </div>

                            {extraTitle && <div className="prizeCard__error">{extraTitle}</div>}
                        </div>
                        <div
                            className="prizeCard__name"
                            dangerouslySetInnerHTML={{ __html: setSpacesInText(title) }}
                        ></div>
                        {this.renderStatus()}
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
    };
}

export default connect(mapStateToProps)(PrizeCard);

PrizeCard.propTypes = {
    thumb: PropTypes.string,
    title: PropTypes.string,
    extraTitle: PropTypes.string,
    status: PropTypes.string,
    statusTitle: PropTypes.string,
    count: PropTypes.number,
    url: PropTypes.string,
};

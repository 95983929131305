import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import ProfileHeader from './profile/Header.jsx';
import ProfileGame from './profile/Game.jsx';
import ProfileCheques from './profile/Cheques.jsx';
import ProfilePrizes from './profile/Prizes.jsx';

import Footer from '../components/Footer.jsx';
import Page from '../components/Page.jsx';
import getHeaders from '../functions/getHeaders';
import requestSuccess from '../functions/requestSuccess';

class Profile extends Page {
    constructor(props) {
        super(props);
        this.state = {};

        this.getPrivateContent = this.getPrivateContent.bind(this);

        this.parent = React.createRef();
    }

    isPrivate = true;

    pageName = 'profile';

    contentName = 'my';

    getPrivateContent() {
        axios
            .get(`${process.env.REACT_APP_API}/api/GetCabinetInfo`, {
                headers: getHeaders(),
            })
            .then(
                (res) => {
                    requestSuccess(res);

                    this.setState({ privateContent: res.data.data });
                },
                () => {},
            );
    }

    componentDidMount() {
        super.componentDidMount();

        this.getPrivateContent();

        document.addEventListener('updateProfile', this.getPrivateContent);
    }

    componentWillUnmount() {
        document.removeEventListener('updateProfile', this.getPrivateContent);
    }

    render() {
        const { privateContent, components } = this.state;

        return (
            <>
                <div ref={this.parent} className="page _profile _inner">
                    <div className="page__section">
                        <ProfileHeader canInputPromocode={privateContent?.canInputPromocode} />
                    </div>
                    <div className="page__section">
                        <ProfileGame game={privateContent?.game} />
                    </div>
                    <div className="page__section">
                        <ProfileCheques
                            items={privateContent?.checks}
                            raffles={components?.raffles}
                        />
                    </div>
                    <div className="page__section">
                        <ProfilePrizes prizes={privateContent?.prizes} />
                    </div>
                    <div className="page__section _withCat">
                        <Footer footer={components?.footer} />
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
    };
}

export default connect(mapStateToProps)(Profile);

Profile.propTypes = {
    device: PropTypes.string,
};

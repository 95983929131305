import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import App from './App.jsx';

class Root extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        const { isLoadFonts, windowIsLoad } = this.props;

        return (
            <>
                <div className={`body__main ${isLoadFonts ? '_fontsLoad' : ''}`}>
                    <div
                        ref={this.parent}
                        className={`body__content ${windowIsLoad ? '_isLoad' : ''}`}
                    >
                        <Helmet></Helmet>
                        <App />
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        windowIsLoad: state.windowIsLoad,
        isMenuShow: state.isMenuShow,
        projectsGuidePopup: state.projectsGuidePopup,
        windowHeight: state.windowHeight,
        isLoadSite: state.isLoadSite,
        windowIsReady: state.windowIsReady,
        isLoadVersion: state.isLoadVersion,
        storePages: state.pages,
        isLoadFonts: state.isLoadFonts,
        isInputFocus: state.isInputFocus,
        loadingImage: state.loadingImage,
    };
}

export default connect(mapStateToProps)(Root);

Root.propTypes = {
    device: PropTypes.string,
    windowIsLoad: PropTypes.any,
    isMenuShow: PropTypes.bool,
    projectsGuidePopup: PropTypes.object,
    windowHeight: PropTypes.number,
    isLoadSite: PropTypes.bool,
    windowIsReady: PropTypes.bool,
    isLoadVersion: PropTypes.bool,
    storePages: PropTypes.object,
    isLoadFonts: PropTypes.bool,
    isInputFocus: PropTypes.bool,
    loadingImage: PropTypes.string,
};

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class IndexHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        return (
            <>
                <div ref={this.parent} className="indexHeader" id="index">
                    <div className="indexHeader__inner">
                        <div className="indexHeader__steps">
                            <div className="indexHeader__stepsInner">
                                <div className="indexHeader__step">
                                    <img
                                        src={require('../../media/Step1.svg').default}
                                        alt=""
                                        className="indexHeader__stepImage"
                                    />
                                </div>
                                <div className="indexHeader__step">
                                    <img
                                        src={require('../../media/Step2.svg').default}
                                        alt=""
                                        className="indexHeader__stepImage"
                                    />
                                </div>
                                <div className="indexHeader__step">
                                    <img
                                        src={require('../../media/Step3.svg').default}
                                        alt=""
                                        className="indexHeader__stepImage"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
    };
}

export default connect(mapStateToProps)(IndexHeader);

IndexHeader.propTypes = {
    device: PropTypes.string,
};

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../../components/Button.jsx';
import AnimateChange from '../../components/AnimateChange.jsx';
import Animate from '../../components/Animate.jsx';
import FormPage from '../../components/FormPage.jsx';

class AnketForm extends FormPage {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            errors: [],
        };

        this.parent = React.createRef();
    }

    fieldsOrder = ['imageAct', 'policy'];

    fields = {
        imageAct: {
            support: 'Скан подписанного акта*',
            type: 'upload',
        },
        policy: {
            type: 'checkbox',
            support: (
                <>
                    Я соглашаюсь с <a href="#">политикой конфиденциальности</a> и{' '}
                    <a href="#">условиями обработки персональных данных</a>
                </>
            ),
        },
    };

    render() {
        const { error, isSuccess = false, isCompleteSuccess, formHeight = 0 } = this.state;

        return (
            <>
                <div ref={this.parent} className="faqForm _anket">
                    <div className="faqForm__inner">
                        <div className="faqForm__head">
                            <div className="innerPageHead">
                                <div className="innerPageHead__title">Запрос Акта</div>
                                <p className="innerPageHead__description _mediumSize">
                                    мы проверили ваши данные, теперь вам необходимо скачать АКТ,{' '}
                                    <br className="_desktopMedia" />
                                    подписать его, отсканировать и загрузить в форме ниже:
                                </p>
                                <div className="innerPageHead__button">
                                    <Button className="_bigSize _red">Скачать акт</Button>
                                </div>
                            </div>
                        </div>
                        <div className="faqForm__content">
                            <div className="faqForm__contentInner">
                                <div
                                    className={`form ${isCompleteSuccess ? '_success' : ''}`}
                                    style={isSuccess ? { height: `${formHeight}px` } : {}}
                                >
                                    <Animate className="form__success" isShow={isSuccess}>
                                        <div className="form__successContent">
                                            <span>Спасибо, Лариса!</span>
                                            Сообщение отправлено, мы свяжемся с&nbsp;вами
                                            в&nbsp;ближайшее время.
                                        </div>
                                    </Animate>
                                    <div className="form__inner">
                                        <div className="form__fields">
                                            {this.fieldsOrder.map((name) =>
                                                this.renderField({ name }),
                                            )}
                                        </div>
                                        <div className="form__required">*обязательные поля</div>
                                        <AnimateChange
                                            className="form__error _center"
                                            renderKey={error}
                                        >
                                            {error ? <div className="error">{error}</div> : null}
                                        </AnimateChange>
                                        <div className="form__button">
                                            <Button className="_green _bigSize">ОТПРАВИТЬ</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
    };
}

export default connect(mapStateToProps)(AnketForm);

AnketForm.propTypes = {
    device: PropTypes.string,
};

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../../components/Button.jsx';
import Link from '../../components/Link.jsx';

class IndexMainPrize extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        const { prizes, user } = this.props;
        const mainPrize = prizes?.main?.items?.[0];

        return (
            <>
                <div ref={this.parent} className={`indexMainPrize ${prizes ? '_ready' : ''}`}>
                    <div className="indexMainPrize__inner">
                        <div className="indexMainPrize__content">
                            <img src={mainPrize?.thumb} alt="" className="indexMainPrize__title" />
                            <p
                                className="indexMainPrize__description"
                                dangerouslySetInnerHTML={{ __html: prizes?.main?.title }}
                            ></p>
                            <Link
                                className="indexMainPrize__button"
                                href={user ? 'cheques' : 'login'}
                            >
                                <Button className="_red _bigSize">ЗАГРУЗИТЬ ЧЕК</Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
    };
}

export default connect(mapStateToProps)(IndexMainPrize);

IndexMainPrize.propTypes = {
    device: PropTypes.string,
    prizes: PropTypes.object,
    user: PropTypes.object,
};

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../../components/Button.jsx';
import Link from '../../components/Link.jsx';
import handlerLoading from '../../functions/handlerLoading';
import logout from '../../functions/logout';
import Animate from '../../components/Animate.jsx';
import handlerPopup from '../../functions/handlerPopup';

class ProfileHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.logout = this.logout.bind(this);

        this.parent = React.createRef();
    }

    logout() {
        handlerLoading.call(this, 'logout').then(() => {
            logout().then(
                () => {
                    handlerLoading.call(this, null);
                },
                () => {
                    handlerLoading.call(this, null);
                },
            );
        });
    }

    render() {
        const { loadingKey } = this.state;
        const { user, canInputPromocode } = this.props;
        const names = [];

        if (user) {
            if (user.personal.firstName) {
                names.push(user.personal.firstName);
            }

            if (user.personal.lastName) {
                names.push(user.personal.lastName);
            }
        }

        return (
            <>
                <div ref={this.parent} className="profileHeader">
                    <div className="profileHeader__inner">
                        <div className="profileHeader__head">
                            <div className="innerPageHead">
                                <div className="innerPageHead__title">Личный кабинет</div>
                            </div>
                        </div>
                        <div className="profileHeader__content">
                            <div className="profileHeader__contentInner">
                                <div className="profileHeader__contentName">
                                    {names.length ? names.join(' ') : '–'}
                                </div>
                                <div className="profileHeader__contentInfo">
                                    <div className="profileHeader__contentId">
                                        ID {user?.userId}
                                    </div>
                                    <Link className="profileHeader__contentBtn" href="anket">
                                        изменить данные
                                    </Link>
                                </div>
                                <div className="profileHeader__contentActions">
                                    <Animate
                                        className="profileHeader__contentAction"
                                        isShow={!!canInputPromocode}
                                    >
                                        <Button
                                            className="_orange"
                                            onClick={() => {
                                                handlerPopup({ name: 'promoPopup', isShow: true });
                                            }}
                                        >
                                            ПРомокод
                                        </Button>
                                    </Animate>
                                    <div className="profileHeader__contentAction _show">
                                        <Button
                                            className="_purple"
                                            onClick={this.logout}
                                            loader={loadingKey === 'logout'}
                                        >
                                            выход
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
    };
}

export default connect(mapStateToProps)(ProfileHeader);

ProfileHeader.propTypes = {
    device: PropTypes.string,
    user: PropTypes.object,
    canInputPromocode: PropTypes.bool,
};
